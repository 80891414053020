

















































import { Component, PropSync, Vue } from "vue-property-decorator";
import store from "@/store";
//  components
import DataAvailabilityModalFooter from "./DataAvailabilityModalFooter.vue";
import DataAvailabilityModalConfigureTags from "./DataAvailabilityModalConfigureTags.vue";
//  types
import { DataQualityTagConfiguration } from "@/types/dataQuality/dataQualityTagConfiguration";
import { DataAvailabilityConfiguration } from "@/types/dataQuality/dataAvailability";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";
import SnackbarModule from "@/store/clients/Snackbar.module";

const DataQuality = getModule(DataQualityModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  components: {
    DataAvailabilityModalFooter,
    DataAvailabilityModalConfigureTags,
  },
})
export default class DataAvailabilityModal extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;

  applySameConfig = false;
  dataAvailabilityConfigModel: DataQualityTagConfiguration[] = [];
  updatedConfigurationTags: number[] = [];

  get dataAvailabilityConfig(): DataQualityTagConfiguration[] {
    if (!DataQuality.dataAvailabilityConfig) return [];
    var config: DataQualityTagConfiguration[] = DataQuality.dataAvailabilityConfig.configurations.map(item => ({
      featureId: item.featureId,
      tagName: item.featureName ?? "",
      observeTimeSpan: this.convertToCorrectTimeUnit(item.warningTimeSeconds)[0],
      observeTimeSpanUnit: this.convertToCorrectTimeUnit(item.warningTimeSeconds)[1],
      criticalTimeSpan: this.convertToCorrectTimeUnit(item.criticalTimeSeconds)[0],
      criticalTimeSpanUnit: this.convertToCorrectTimeUnit(item.criticalTimeSeconds)[1],
    }));
    return config;
  }

  get updatedConfigurationList(): DataAvailabilityConfiguration[] {
    return this.dataAvailabilityConfigModel
      .filter(item => (item.featureId !== null ? this.updatedConfigurationTags.includes(item.featureId) : false))
      .map(item => ({
        featureId: item.featureId,
        featureName: item.tagName,
        warningTimeSeconds: this.convertToSeconds(item.observeTimeSpan, item.observeTimeSpanUnit),
        criticalTimeSeconds: this.convertToSeconds(item.criticalTimeSpan, item.criticalTimeSpanUnit),
      }));
  }

  async created(): Promise<void> {
    await Promise.all([DataQuality.refreshDataAvailabilityConfig()]);
    this.dataAvailabilityConfigModel = this.dataAvailabilityConfig;
    this.applySameConfig = DataQuality.dataAvailabilityConfig?.isGlobalConfig ?? false;
    if (this.applySameConfig) {
      this.commonConfig = {
        featureId: null,
        tagName: "",
        observeTimeSpan: this.dataAvailabilityConfigModel[0].observeTimeSpan,
        observeTimeSpanUnit: this.dataAvailabilityConfigModel[0].observeTimeSpanUnit,
        criticalTimeSpan: this.dataAvailabilityConfigModel[0].criticalTimeSpan,
        criticalTimeSpanUnit: this.dataAvailabilityConfigModel[0].criticalTimeSpanUnit,
      };
    }
  }

  convertToCorrectTimeUnit(numberInSeconds: number): [string, string] {
    if (numberInSeconds >= 31536000) {
      return [Math.round(numberInSeconds / 31536000).toString(), "year(s)"];
    }
    if (numberInSeconds >= 2592000) {
      return [Math.round(numberInSeconds / 2592000).toString(), "month(s)"];
    }
    if (numberInSeconds >= 86400) {
      return [Math.round(numberInSeconds / 86400).toString(), "day(s)"];
    }
    if (numberInSeconds >= 3600) {
      return [Math.round(numberInSeconds / 3600).toString(), "hour(s)"];
    }
    if (numberInSeconds >= 60) {
      return [Math.round(numberInSeconds / 60).toString(), "minute(s)"];
    }

    return [numberInSeconds.toString(), "seconds"];
  }

  convertToSeconds(number: string, unit: string): number {
    if (unit === "year(s)") {
      return Number(number) * 31536000;
    }
    if (unit === "month(s)") {
      return Number(number) * 2592000;
    }
    if (unit === "day(s)") {
      return Number(number) * 86400;
    }
    if (unit === "hour(s)") {
      return Number(number) * 3600;
    }
    if (unit === "minute(s)") {
      return Number(number) * 60;
    }
    return Number(number);
  }

  commonConfig: DataQualityTagConfiguration = {
    featureId: null,
    tagName: "",
    observeTimeSpan: "1",
    observeTimeSpanUnit: "hour(s)",
    criticalTimeSpan: "1",
    criticalTimeSpanUnit: "day(s)",
  };

  get getButtonAvailability(): boolean {
    if (this.commonConfig.observeTimeSpan === "" || this.commonConfig.observeTimeSpan === "0") {
      return true;
    }
    if (this.commonConfig.criticalTimeSpan === "" || this.commonConfig.criticalTimeSpan === "0") {
      return true;
    }
    const invalidTag = this.dataAvailabilityConfigModel.find(tag => {
      if (tag.tagName === "") {
        return true;
      }
      if (tag.observeTimeSpan === "" || tag.observeTimeSpan === "0") {
        return true;
      }
      if (tag.criticalTimeSpan === "" || tag.criticalTimeSpan === "0") {
        return true;
      }
      if (isNaN(Number(tag.observeTimeSpan)) || isNaN(Number(tag.criticalTimeSpan))) {
        return true;
      }
      return false;
    });
    if (invalidTag) {
      return true;
    }
    return false;
  }

  get alertMessage(): string {
    const invalidTag = this.dataAvailabilityConfigModel.find(tag => {
      if (tag.tagName === "") {
        return true;
      }
      if (tag.observeTimeSpan === "" || tag.observeTimeSpan === "0") {
        return true;
      }
      if (tag.criticalTimeSpan === "" || tag.criticalTimeSpan === "0") {
        return true;
      }
      if (isNaN(Number(tag.observeTimeSpan)) || isNaN(Number(tag.criticalTimeSpan))) {
        return true;
      }
      return false;
    });

    if (this.commonConfig.observeTimeSpan === "" || this.commonConfig.observeTimeSpan === "0") {
      return "Observe time span cannot be empty or 0";
    }
    if (this.commonConfig.criticalTimeSpan === "" || this.commonConfig.criticalTimeSpan === "0") {
      return "Critical time span cannot be empty or 0";
    }
    if (isNaN(Number(this.commonConfig.observeTimeSpan)) || isNaN(Number(this.commonConfig.criticalTimeSpan))) {
      return "Time span must be a number";
    }

    if (invalidTag) {
      if (invalidTag.tagName === "") {
        return "Tag name cannot be empty";
      }
      if (invalidTag.observeTimeSpan === "" || invalidTag.observeTimeSpan == "0") {
        return "Observe time span cannot be empty or 0";
      }
      if (invalidTag.criticalTimeSpan === "" || invalidTag.criticalTimeSpan == "0") {
        return "Critical time span cannot be empty or 0";
      }
      if (isNaN(Number(invalidTag.observeTimeSpan)) || isNaN(Number(invalidTag.criticalTimeSpan))) {
        return "Time span must be a number";
      }
    }
    return "";
  }

  async submitConfiguration(): Promise<void> {
    if (this.applySameConfig) {
      let config = [
        {
          featureId: this.commonConfig.featureId,
          warningTimeSeconds: this.convertToSeconds(this.commonConfig.observeTimeSpan, this.commonConfig.observeTimeSpanUnit),
          criticalTimeSeconds: this.convertToSeconds(this.commonConfig.criticalTimeSpan, this.commonConfig.criticalTimeSpanUnit),
        },
      ];
      try {
        await DataQuality.updateDataAvailabilityConfig(config);
        this.applySameConfigurationToAllTags();
        Snackbar.showSnackbar({
          text: `Configurations was successfully <b>updated</b>`,
          color: "success",
        });
      } catch (error) {
        Snackbar.showSnackbar({ text: "Failed to submit configurations" });
      }
    } else {
      let config: DataAvailabilityConfiguration[] = this.updatedConfigurationList;
      try {
        await DataQuality.updateDataAvailabilityConfig(config);
        Snackbar.showSnackbar({
          text: `Configurations was successfully <b>updated</b>`,
          color: "success",
        });
      } catch (error) {
        Snackbar.showSnackbar({ text: "Failed to submit configurations" });
      }
    }

    this.closeModal();
  }

  applySameConfigurationToAllTags(): void {
    this.dataAvailabilityConfigModel.forEach(tag => {
      tag.observeTimeSpan = this.commonConfig.observeTimeSpan;
      tag.observeTimeSpanUnit = this.commonConfig.observeTimeSpanUnit;
      tag.criticalTimeSpan = this.commonConfig.criticalTimeSpan;
      tag.criticalTimeSpanUnit = this.commonConfig.criticalTimeSpanUnit;
    });
  }

  updateConfigurationTag(data: DataQualityTagConfiguration): void {
    if (data.featureId === null) return;
    if (this.updatedConfigurationTags.includes(data.featureId)) return;
    this.updatedConfigurationTags.push(data.featureId);
  }

  closeModal(): void {
    this.dialog = false;
  }
}
