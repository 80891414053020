



















































































































































import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import DataQualityIndicator from "@/components/dataQuality/DataQualityIndicator.vue";
import { DataQualityTagConfiguration } from "@/types/dataQuality/dataQualityTagConfiguration";

@Component({
  components: {
    DataQualityIndicator,
  },
})
export default class DataAvailabilityModalConfigureTags extends Vue {
  @PropSync("selectedTags", { type: Array, default: [] }) tagsToConfigure!: DataQualityTagConfiguration[];
  @PropSync("commonConfiguration", { type: Object, default: [] }) commonConfig!: DataQualityTagConfiguration;
  @PropSync("switch", { type: Boolean, default: false }) applySameConfig!: boolean;

  @Prop({ type: Boolean, default: () => [] }) loading!: boolean[];

  items = [{ state: "second(s)" }, { state: "minute(s)" }, { state: "hour(s)" }, { state: "day(s)" }, { state: "month(s)" }, { state: "year(s)" }];
  updateConfigurationTag(data: DataQualityTagConfiguration) {
    this.$emit("updateConfigurationTag", data);
  }
}
