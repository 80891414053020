






























import { Vue, Component } from "vue-property-decorator";
import DataAvailabilityModal from "@/components/dataQuality/dataAvailabilityModal/DataAvailabilityModal.vue";
import DataQualityTable from "@/components/dataQuality/dataQualityTable/DataQualityTable.vue";
import DataQualityIndicatorLegend from "@/components/dataQuality/DataQualityIndicatorLegend.vue";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import DataQualityModule from "@/store/clients/DataQuality.module";
import store from "@/store";
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataQualityTable,
    DataAvailabilityModal,
    DataQualityIndicatorLegend,
  },
})
export default class DataAvailability extends Vue {
  dialog = false;
  searchQuery = "";

  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshDataAvailability()]);
  }

  get dataAvailability(): any {
    var dataAvailability = DataQuality.dataAvailability.map(item => {
      const vessel = Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId);
      return {
        vesselName: {
          value: vessel ? vessel.name : "Unknown Vessel",
        },
        overallStatus: {
          value: (() => {
            return item.overallOutageState;
          })(),
        },
        stw: {
          value: (() => {
            const row = item.data.find(row => row.featureName === "Speed Through Water");
            return row ? row.outageState : 0;
          })(),
        },
        draft: {
          value: (() => {
            const row = item.data.find(row => row.featureName === "Draft Mean");
            return row ? row.outageState : 0;
          })(),
        },
        speedLog: {
          value: (() => {
            const row = item.data.find(row => row.featureName === "speedLog");
            return row ? row.outageState : 0;
          })(),
        },
        gefoc1: {
          value: (() => {
            const row = item.data.find(row => row.featureName === "gefoc1");
            return row ? row.outageState : 0;
          })(),
        },
        gefoc2: {
          value: (() => {
            const row = item.data.find(row => row.featureName === "gefoc2");
            return row ? row.outageState : 0;
          })(),
        },
      };
    });
    return Boolean(this.searchQuery !== null && this.searchQuery.trim())
      ? dataAvailability.filter(item => item.vesselName.value.toLowerCase().includes(this.searchQuery.toLowerCase()))
      : dataAvailability;
  }

  get tableLoading(): boolean {
    return DataQuality.loadingState;
  }

  headers: DataQualityHeader[] = [
    {
      text: "Vessel",
      type: "string",
      value: "vesselName",
      width: "200px",
      sortable: true,
      class: "text-left",
    },
    {
      text: "Overall Status",
      type: "dataIndicator",
      value: "overallStatus",
      tooltip: "Indicates overall data availability status of the vessel and is determined by the most critical status.",
      width: "150px",
      class: "text-center",
    },
    {
      text: "STW",
      type: "dataIndicator",
      value: "stw",
      width: "100px",
      class: "text-center",
    },
    {
      text: "Draft",
      type: "dataIndicator",
      value: "draft",
      width: "100px",
      class: "text-center",
    },
    {
      text: "SP#1",
      type: "dataIndicator",
      value: "speedLog",
      width: "100px",
      class: "text-center",
    },
    {
      text: "SP#2",
      type: "dataIndicator",
      value: "gefoc1",
      width: "100px",
      class: "text-center",
    },
    {
      text: "ME #1FOC",
      type: "dataIndicator",
      value: "speedLog",
      width: "100px",
      class: "text-center",
    },
    {
      text: "ME #2FOC",
      type: "dataIndicator",
      value: "speedLog",
      width: "100px",
      class: "text-center",
    },
    {
      text: "G/E #1FOC",
      type: "dataIndicator",
      value: "gefoc1",
      width: "100px",
      class: "text-center",
    },
    {
      text: "G/E #2FOC",
      type: "dataIndicator",
      value: "gefoc2",
      width: "100px",
      class: "text-center",
    },
    {
      text: "G/E #3FOC",
      type: "dataIndicator",
      value: "gefoc1",
      width: "100px",
      class: "text-center",
    },
    {
      text: "G/E #4FOC",
      type: "dataIndicator",
      value: "speedLog",
      width: "100px",
      class: "text-center",
    },
  ];

  closeModal(): void {
    this.dialog = false;
  }
}
